.settings {
  .settings__item {
    background-color: $gray-200;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    text-decoration: none;
    margin-bottom: 1rem;
  }
}
