@import './app';
@import './footer';
@import './form';
@import './header';
@import './nav';
@import './offcanvas';
@import './table';
@import './portal';

.full-content {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../assets/images/Main.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 1rem;
}

.modal-header {
  padding-bottom: 0;
}
.modal-footer {
  padding-top: 0;
}
