.form-filter {
  width: 300px;
  max-width: 100%;
}

form.form-default {
  .form-label {
    color: $gray-600;
    font-size: 0.75rem;
    margin-bottom: 0;
  }
  .form-control:not([type='file']):not(textarea) {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  input[type='number'].form-control {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .form-select {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  input:read-only {
    color: $gray-500;
  }

  .input-group {
    .input-group-text {
      background: none;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
    }
  }

  .PhoneInput {
    border-bottom: 1px solid $gray-400;
    input {
      border: none;
      border-radius: 0;
      padding-top: 0.375rem;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  textarea {
    height: 100px;
  }
}
