.table-responsive {
  position: relative;
}

.table-main {
  thead {
    tr th {
      background-color: $theme-color;
      color: #fff;
    }
  }
}

.table-list {
  thead {
    tr th {
      color: $gray-600;
      font-size: 0.75rem;
    }
  }
}
