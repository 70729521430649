.portal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .portal__header {
    color: #fff;
    background: $theme-color url(../../assets/images/bg-sidebar-2.png) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    height: 75px;
    width: 100%;
    padding: 1rem 0;
    z-index: $zindex-fixed;

    @include media-breakpoint-up(md) {
      flex-direction: column;
      //position: fixed;
      height: 100%;
      width: 260px;
      overflow-y: auto;
    }
  }

  .portal__header-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-bottom: 1px Solid #fff;
      height: auto;
      flex-direction: column;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }

  .portal__header-avatar {
    width: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
      width: 80%;
    }
  }

  .portal__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 75px;
    width: 100%;
    overflow-x: hidden;

    @include media-breakpoint-up(md) {
      margin-left: 260px;
      padding-top: 0;
    }
  }
}

.portal-content-sm {
  margin: 0 auto;
  width: 300px;
  max-width: 100%;
}
.portal-content {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
}

.portal-content-lg {
  margin: 0 auto;
  width: 500px;
  max-width: 100%;
}
