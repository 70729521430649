.portal-banner {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('../../assets/images/banner-1.jpg');
  background-position: center;
  background-size: cover;

  &__logo {
    margin-left: auto;
    max-width: 80px;
  }
}
.ph-logo {
  width: 35%;
  min-width: 80px;
  max-width: 200px;
}

@media screen and (max-width: 768px) {
  .ph-logo {
    width: 20%;
  }
}
