header {
  color: #fff;
  padding: 0 !important;

  nav {
    background-color: $theme-color;
  }
}

.navbar-nav {
  .nav-link {
    color: #fff !important;
    background-color: transparent;
    border: none;
    text-align: left;
    width: 100%;
  }
}

.nav-brand-img {
  //width: clamp(50px, 7vw, 200px);
  width: 75px;
  margin-right: 0.5rem;
}

.icon {
  display: block;
  margin: 0 auto 1.5rem;
  width: 150px;
}
.icon--sm {
  width: 50px;
}
