.splash {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
}

.loading-overlay {
  border-radius: inherit;
  border: none;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  background-color: rgba(#fff, 0.4);
  font-size: 1.5rem;
  overflow: hidden;
  touch-action: none;
  z-index: 10;

  .loading-overlay__content {
    border: none;
    position: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    text-align: center;

    .spinner-border {
      color: $theme-color;
      height: 3rem;
      width: 3rem;
    }
  }

  .scan-loader__bar {
    background-image: linear-gradient(90deg, rgba(127, 127, 127, 0), rgba(127, 127, 127, 1));
    border: none;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right: 0;
    // height: 100%;
    // width: 100%;
    //left: -100%;
    transform: translateX(-100%);
    animation-name: slidebar;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
}

.loading-overlay--bg {
  background-color: rgba(#fff, 0.5);
}

.loading-overlay--solidbg {
  background-color: rgb(212, 212, 212);
}

.loading-overlay--transparentbg {
  background-color: transparent;
}

.scan-loader {
  border-radius: inherit;
  background-color: rgba(212, 212, 212, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .scan-loader__bar {
    background-image: linear-gradient(90deg, rgba(127, 127, 127, 0), rgba(127, 127, 127, 1));
    position: absolute;
    height: 100%;
    width: 100%;
    --left: -100%;
    transform: translateX(-100%);
    animation-name: slidebar;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
}

@keyframes slidebar {
  0% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.progress-bar {
  background-color: $blue;
  width: 100%;
}
