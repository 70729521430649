#close-modal-donation {
  background-color: $theme-color;
  color: white;

  &:focus {
    box-shadow: none;
    outline: 0;
    border-color: transparent;
  }
}
