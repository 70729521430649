.avatar {
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid $gray-400;
  overflow: hidden;
  width: 5rem;
  max-width: 100%;

  .avatar__img {
    display: block;
    height: auto;
    width: 100%;
    position: relative;
    border: none;
    border-radius: 50%;
    padding: 100% 0 0 0;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 105%;
      min-width: 105%;
      width: 100%;
      transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }
}

.avatar--white {
  background-color: #fff;
  border-color: #fff;
}

.avatar--shadow {
  box-shadow: 0 0 8px 3px #b8b8b8;
}

.avatar--noborder {
  border: none;
}

.avatar--sm {
  width: 3rem;
}

.avatar--lg {
  width: 10rem;
}

.avatar-wrapper {
  display: inline-block;
  position: relative;

  .avatar-wrapper__edit {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    border-radius: 50%;
    border: 1px;
    height: 30px;
    width: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 0 8px 3px #b8b8b8 !important;

    i {
      color: #000;
      line-height: 1;
    }
  }
}
