.sidebar {
  background: $theme-color url(../../assets/images/bg-sidebar.png) no-repeat center center;
  background-size: cover;
  padding: 1rem 0;
  position: fixed;
  //max-width: 240px;
  height: 100px;
  overflow-y: auto;

  .sidebar__nav {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
  }

  .sidebar__nav-link {
    display: block;
    color: #fff;
    background-color: transparent;
    padding: 0.65rem 1rem;
    text-align: left;
    width: 100%;
    border: none;
    text-decoration: none;
    //border-top: 1px Solid #fff;
    transition: background-color 0.3s ease-out;

    &.active {
      font-weight: 600;
      color: #fed017; //$font-dark;
      //background-color: #fff;
    }

    &:hover {
      color: $font-dark;
      background-color: #fff;
      transition: background-color 0.3s ease-out;
    }
  }

  @include media-breakpoint-up(md) {
  }
}

// @media screen and (max-width: 767px) {
//   .sidebar {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     position: relative;
//     padding: 1rem;
//     max-width: 100%;

//     .sidebar__toggle {
//       z-index: 999;

//       &:hover {
//         cursor: pointer;
//       }

//       &.bar {
//         transform-origin: left;
//         transition: 0.3s ease-out;
//       }

//       &.active {
//         position: fixed;
//         right: 15px;
//       }

//       &.active > #bar1 {
//         margin: 0;
//         transform-origin: left;
//         transform: rotate(40deg);
//         transition: 0.3s ease-out;
//       }
//       &.active > #bar2 {
//         opacity: 0;
//         transition: 0.3s ease-out;
//       }
//       &.active > #bar3 {
//         margin: 0;
//         transform-origin: left;
//         transform: rotate(-40deg);
//         transition: 0.3s ease-out;
//       }
//     }
//   }

//   .sidebar div:nth-of-type(1) {
//     display: flex;
//     align-items: center;

//     img {
//       min-width: 50px;
//       width: 10%;
//       max-width: 350px;
//     }
//   }

//   .bar {
//     width: 25px;
//     height: 4px;
//     background-color: white;
//     margin: 4px 0;
//     transition: 0.3s ease-in-out;
//   }

//   .sidebar__nav {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: $theme-color;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     overflow: hidden;
//     transform: translateX(-100%);
//     transition: 0.3s ease-in-out;
//     z-index: 100;

//     &.active {
//       transform: translateX(0%);
//       transition: 0.3s ease-in-out;
//     }

//     .sidebar__nav-link {
//       //text-align: center;
//       border: none;

//       &:last-child {
//         border: none;
//       }
//     }
//   }
// }
