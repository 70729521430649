.user {
  position: relative;

  .user__avatar {
    background: $theme-color url(../../assets/images/bg-sidebar.png) no-repeat center center;
    background-size: cover;
  }

  .user__widget {
    color: #fff;
    background-color: $theme-color;
    border-radius: 0.5rem;
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    padding: 1rem;

    &:hover {
      background-color: $theme-color-light;
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .user__detail-header {
    color: $gray-600;
    font-size: 0.75rem;
    margin-bottom: 0;
  }
}
