//@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed');
//@import './scss/variables-reference';
@import './scss/variables';

$font-family-base: 'Barlow Semi Condensed',
  // Cross-platform generic font family (default user interface font)
  system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  'Segoe UI',
  // Android
  Roboto,
  // Basic web fallback
  Arial,
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$primary: $theme-color;
$link-color: #0d6efd;
$modal-content-border-width: 0;

@import '~bootstrap/scss/bootstrap';

@import './scss/0-base/base';
@import './scss/1-layout/layout';
@import './scss/2-module/module';
@import './scss/3-state/state';

@import './scss/button';
@import './scss/typography.scss';

@import '~bootstrap-icons';
@import 'sweetalert2/src/sweetalert2.scss';
@import 'node_modules/flatpickr/dist/flatpickr';
@import 'node_modules/flatpickr/dist/themes/material_blue';
@import 'react-phone-number-input/style.css';
