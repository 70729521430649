//@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IM+Fell+French+Canon+SC&display=swap');

html,
body {
  height: 100%;
}

main#public-main {
  background-color: #fff;
  position: relative;
  padding-top: 70px;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
  }
}

main#portal-main {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem;

  @include media-breakpoint-up(md) {
    padding: 2rem;
  }
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $theme-color;
}

fieldset {
  legend {
    color: $theme-color-light !important;
  }
}

form {
  max-width: 100%;
}

ul {
  max-width: 100%;
}

input[type='number'] {
  -moz-appearance: textfield;
}
