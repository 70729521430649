.landing {
  color: #fff;
  margin: 0 auto;
  text-align: center;

  .landing__logo {
    width: 10%;
    min-width: 100px;
    max-width: 250px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .landing__button {
    font-size: clamp(15px, 2vw, 50px) !important;
    font-weight: 500;
    background-color: transparent;
    color: white;
    padding: 1rem 65px;
    border-radius: 50px;
    border: 2px solid white;
    transition: 0.5s ease;
    text-decoration: none;
  }

  .landing__button:hover {
    background-color: white;
    color: $theme-color;
    cursor: pointer;
  }
}

#sub-headline {
  font-size: clamp(20px, 2vw, 100px) !important;
  font-weight: 500;
  color: white;
}

#main-headline {
  letter-spacing: 4px;
  text-decoration: none;
  font-size: clamp(40px, 5vw, 200px) !important;
  font-weight: 900;
  color: white;
  font-family: 'IM Fell French Canon SC';
}

#sub-headline2 {
  text-decoration: none;
  font-size: clamp(20px, 2vw, 100px) !important;
  font-weight: 500;
  color: white;
}

.social-menu {
  margin-top: 50px;
  margin-bottom: 30px;

  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;

  li {
    margin: 0 10px;
  }

  a {
    min-width: 40px;
    max-width: 150px;
    width: 2vw;
    font-size: 150%;
    aspect-ratio: 1/1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: black;
    background-color: white;
    text-align: center;
    transition: 0.5s;
    transform: translate(0, 0px);
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);
  }

  a:visited {
    color: black;
  }

  a:hover {
    transform: rotate(0deg) skew(0deg) translate(0, -5px);

    > svg {
      color: white;
    }
  }
  li:nth-child(1) a:hover {
    background-color: #3b5999;
  }
  li:nth-child(2) a:hover {
    background-color: #55acee;
  }
  li:nth-child(3) a:hover {
    background-color: #b53393;
  }
  li:nth-child(4) a:hover {
    background-color: #cd201f;
  }
}
