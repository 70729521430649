.dashboard {
  .dashboard__widget {
    color: #fff;
    background-color: $theme-color;
    border-radius: 0.5rem;
    height: 80px;
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;

    &:hover {
      background-color: $theme-color-light;
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
  }
}
