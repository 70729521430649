.login {
  border-radius: 0.5rem;
  margin: auto;
  width: 400px;
  max-width: 100%;
  padding: 1.5rem;
  background-color: #ffffff;
}

.password_login::-ms-reveal,
.password_login::-ms-clear {
  display: none;
}
.password_login::-ms-reveal,
.password_login::-ms-clear {
  display: none;
}

.register {
  border-radius: 0.5rem;
  margin: auto;
  width: 900px;
  max-width: 100%;
  padding: 1.5rem 2rem;
  background-color: #ffffff;
}
