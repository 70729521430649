.page404 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../assets/images/banner-2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &__content {
    color: #fff;
    border-radius: 1rem;
    align-items: center;
    padding: 2rem;
    margin: 0 auto;
    text-align: center;
  }

  &__header {
    font-size: 8rem;
  }
}
