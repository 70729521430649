.swal2-container {
  .swal2-popup {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .swal2-actions {
    width: 100%;

    button {
      background-color: $theme-color;
      color: #fff;
      padding-left: 2rem;
      padding-right: 2rem;
      //width: 50%;
    }
  }
}
