.offcanvas-menu {
  background: $theme-color url(../../assets/images/bg-sidebar-2.png) no-repeat center center;
  background-size: cover;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  //overflow-y: auto;

  @include media-breakpoint-up(md) {
    background: transparent;
    width: 100%;
  }

  .offcanvas-header {
    align-items: flex-start;
  }

  .offcanvas-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .dropdown-menu {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  .offcanvas-menu__nav-link {
    display: block;
    color: #fff;
    background-color: transparent;
    padding: 0.65rem 1rem;
    text-align: left;
    width: 100%;
    border: none;
    text-decoration: none;
    //border-top: 1px Solid #fff;
    transition: background-color 0.3s ease-out;

    &.active {
      font-weight: 600;
      color: #fed017; //$font-dark;
      //background-color: #fff;
    }

    &:hover {
      color: $font-dark;
      background-color: #fff;
      transition: background-color 0.3s ease-out;
    }
  }
}
