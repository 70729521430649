.btn-alert {
  background-color: $theme-color;
  color: #fff;
  width: 100%;
}

.btn-loader {
  position: relative;
  overflow: hidden;

  .btn-loader__overlay {
    background-color: rgba(#000, 0.2);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    & + .btn-loader__content {
      visibility: hidden;
    }
  }

  .btn-loader__wrapper {
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.btn-sm {
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
  &.btn-lg {
    .spinner-border {
      width: 2rem;
      height: 2rem;
    }
  }
}

.btn-wide {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.btn-wrapper {
  text-align: right;
  a,
  button {
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0 0.5rem;
  }
}

.btn-xs {
  font-size: 12px;
  padding: 0.1rem 0.25rem;
}

.btn-link {
  padding: 0;
  text-decoration: none;
}

.btn-image {
  border: none;

  &:active {
    //border: none;
  }
}
