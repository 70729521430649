.text-blue {
  color: $theme-color;
}

.text-blue-light {
  color: $theme-color-light;
}

.banner1 {
  height: 200px;
  padding: 50px 0;
  background-image: url('../../assets/images/banner-1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner2 {
  height: 200px;
  padding: 50px 0;
  background-image: url('../../assets/images/banner-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.socials {
  font-size: 2vw;
}

.card-events-home {
  border: none;
  height: 100%;
  max-width: 650px;
  -webkit-box-shadow: 3px 4px 9px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 9px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 9px -1px rgba(0, 0, 0, 0.75);

  .card-img-top {
    width: 100%;
    //aspect-ratio: 4/3;
  }
}

.artist-video,
.featured-photos {
  width: 100%;
  max-width: 1000px;
  aspect-ratio: 16/9;
}

.events {
  .swiper-wrapper {
    align-items: stretch !important;
  }

  .swiper-slide {
    height: auto !important;
  }
}
